/**
 * Hardware Buildup
 * Hardware Buildup
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = FullPower, 1 = LowPower, 2 = NoPower
 */
export enum DevicePowerTypeEnum {
    FullPower = 0,
    LowPower = 1,
    NoPower = 2
}

