/**
 * Hardware Buildup
 * Hardware Buildup
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = UKNOWN, 1 = ESP32, 2 = NRF52832, 3 = NRF52833, 4 = NRF52840, 5 = NRF52840_FEM, 6 = NRF52840_FEM_DEPR
 */
export enum FirmwareFlavourTypesEnum {
    UKNOWN = 0,
    ESP32 = 1,
    NRF52832 = 2,
    NRF52833 = 3,
    NRF52840 = 4,
    NRF52840_FEM = 5,
    NRF52840_FEM_DEPR = 6
}

