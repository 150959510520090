/**
 * Hardware Buildup
 * Hardware Buildup
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoutineNodeUiModel } from './routine-node-ui-model';
import { RoutineNodeConnectorModel } from './routine-node-connector-model';


export interface RoutineNodeModel { 
    key: string;
    ui: RoutineNodeUiModel;
    data?: object;
    inputs: Array<RoutineNodeConnectorModel>;
    outputs: Array<RoutineNodeConnectorModel>;
    type: RoutineNodeModelTypeEnum;
}
export enum RoutineNodeModelTypeEnum {
    ProductEventActionNode = 'ProductEventActionNode',
    ActivateSceneNode = 'ActivateSceneNode',
    TimerNode = 'TimerNode',
    ProductEventTriggerNode = 'ProductEventTriggerNode',
    ScheduledTriggerNode = 'ScheduledTriggerNode'
};



